import React from "react";
import {employements, works} from "../../Utils/Items";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

const NARadioBtn = () => <FormControlLabel
    value={-1}
    style={{margin: 0, padding: "2px"}}
    control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />}
    label="NA"
/>

const WorkModule = ({work, handleChange}) => {
    return <div className="identity-element-work">
        <p>Nature of work</p>
        <FormControl component="fieldset">
            <RadioGroup name="work" value={work} onChange={handleChange}>
                {works.map((work, key) => {
                    return <FormControlLabel
                        key={key}
                        style={{margin: 0, padding: "0 2px"}}
                        value={key}
                        control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />}
                        label={work}
                    />
                })}
                <NARadioBtn />
            </RadioGroup>
        </FormControl>
    </div>
}

const EmploymentModule = ({employement, handleChange}) => {
    return <div className="identity-element-work">
        <p>Employement status</p>
        <FormControl component="fieldset">
            <RadioGroup name="employement" value={employement} onChange={handleChange}>
                {employements.map((empl, key) => {
                    return <FormControlLabel
                        key={key}
                        style={{margin: 0, padding: "2px"}}
                        value={key}
                        control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />}
                        label={empl}
                    />
                })}
                <NARadioBtn />
            </RadioGroup>
        </FormControl>
    </div>
}

/**
 * Displays the fields related to patient daily work life (step 2).
 *
 * @param {boolean} error_message
 * @param {int} work ∈ {0, 1, 2, 3, 4}
 * @param {int} employement ∈ {0, 1, 2, 3}
 * @param {function} handleChange
 *
 * @returns {JSX.Element}
 */
export const Work = ({work, employement, handleChange}) => {
    return <div className="identity-work">
        <WorkModule work={work} handleChange={handleChange}/>
        <EmploymentModule employement={employement} handleChange={handleChange} />
    </div>
}