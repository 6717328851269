import './ImportImages.css'
import {ListOfFilesModule} from "./AddImages/Modules/ListOfFilesModule";
import {ImportModule} from "./AddImages/Modules/ImportModule";

//"SagT1", "SagT2", "AxT1", "AxT2",

export const mod_list = ['SagT1w',
    'SagT2w',
    "SagT2 STIR",
    "Sag Flair",
    "Sag DIXON w",
    "Sag DIXON f",
    "Sag DIXON in",
    "Sag DIXON opp",
    "SagT2 3D",
    "AxT1w",
    "AxT2w",
    "AxT2 3D",
    "Coronal T2 STIR",
    "Unknown"
]

export const ImportImages = ({className, handleFile, errorFile, modality, handleModality, errorMod, handleSubmit, files, dcm, handleDeleteLine, handleDeleteMod}) => {
    return <div className={className}>
        <ImportModule
            dcm={dcm}
            files={files}
            handleFile={handleFile}
            errorFile={errorFile}
            modality={modality}
            handleModality={handleModality}
            errorMod={errorMod}
            handleSubmit={handleSubmit}
        />
        {dcm && dcm.length >= 1 && <ListOfFilesModule
            dcm={dcm}
            handleDeleteLine={handleDeleteLine}
            handleDeleteMod={handleDeleteMod}
        />}
    </div>
}