import React from 'react'
import './ClinicalData.css'
import {Link} from "react-router-dom"
import {FormControl} from "@material-ui/core"
import {UploadImageStep} from "./UploadImageStep"
import {Overview} from "./Overview/Overview"
import {MAX_STEPS, steps} from "../Utils/Items"
import {postFile, putCollectorData} from "../../services/CollectorService"
import {mod_list} from "../ImportImages"
import {Grid} from "@mui/material"
import {Identity} from "./Steps/IdentityStep";
import {MedicalHistory} from "./Steps/MedHistoryStep";
import {Work} from "./Steps/WorkStep";
import {Surgery} from "./Steps/SurgeryStep";
import {SuiviUploadModule} from "../AddImages/Modules/SuiviUploadModule";
import {ClinicalDataUpload, FinishedSymbole, get_surgery_values} from "./utils";
import CMStepper, {CMButtonStepper} from "../Utils/Stepper";
import {LogoutBlueBtn, NavBarBtn} from "../Utils/CustomButtons";

const tabs = ['surgery', 'spine_op', 'treatment', 'radio']

export default class ClinicalData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            error: false,

            label: 'NA',
            sex: 'NA',
            age: -1,
            height: -1,
            weight: -1,

            smoke: 'NA',
            diabete: -1,
            lumbar_pain: -1,
            rad_pain: -1,
            radiculalgia: 'NA',
            odi: -1,

            work: -1,
            employement: -1,

            radiology: {
                radiology_signs: '',

                surg_opinion: '',
                surgery: Array(5).fill(Array(7).fill('')),
                //surgery: testValuesRadioSurgery[0],
                surgery_details: '',

                spine_op: Array(4).fill(Array(7).fill('')),
                //spine_op: testValuesRadioSurgery[1],
                spine_op_details: '',

                treatment: Array(7).fill('ND'),
                treatment_details: '',

                radio_normale: true,
                radio: Array(3).fill(Array(7).fill('')),
                //radio: testValuesRadioSurgery[2],
                radio_details: '',
            },

            //image_names: [[['test_images_names'], 'test_modality']],
            dcm_files: [],

            data_progress: 0,
            error_data: false,
            clinical_data_finished: false,
            files_progress: {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
            },
            files_finished: { //-1 if not started yet, 0 if started, 1 if finished
                0: -1,
                1: -1,
                2: -1,
                3: -1,
                4: -1,
                5: -1,
                6: -1,
                7: -1,
                8: -1,
            },
            error_upload: [],

            finished: false,
        }
    }

    /**
     * Stores text field from 'Radiology and surgery' tab into state.
     *
     * @param {int} tab - column where the value has to be stored ∈ {0, 1, 2, 3, 4}
     * @param {String} value - value of the text area to be modified
     */
    handleDetails(tab, value) {
        if (tab === 0) this.setState(prevState => ({
            radiology: {...prevState.radiology, radiology_signs: value}
        }))
        else if (tab === 1) this.setState(prevState => ({
            radiology: {...prevState.radiology, surgery_details: value}
        }))
        else if (tab === 2) this.setState(prevState => ({
            radiology: {...prevState.radiology, spine_op_details: value}
        }))
        else if (tab === 3) this.setState(prevState => ({
            radiology: {...prevState.radiology, treatment_details: value}
        }))
        else if (tab === 4) this.setState(prevState => ({
            radiology: {...prevState.radiology, radio_details: value}
        }))
        else if (tab === 5) this.setState(prevState => ({
            radiology: {...prevState.radiology, surg_opinion: value}
        }))
        else if (tab === 6) this.setState(prevState => ({
            radiology: {...prevState.radiology, radio_normale: value}
        }))
    }

    /**
     * Stores treatment values from 'Radiology and surgery' tab into state.
     *
     * @param {int} treatment - drug's id that corresponds to the index of the table ∈ {0, 1, 2, 3, 4, 5, 6}
     * @param {String} value - ∈ {'Yes', 'No', 'Previous', 'ND'}
     */
    handleTreatment(treatment, value) {
        const treat = this.state.radiology.treatment.slice()
        treat[treatment] = value
        this.setState(prevState => ({radiology: {...prevState.radiology, treatment: treat}}))
    }

    /**
     * Reset state values of a subtab of 'Radiology and surgery' to ''.
     *
     * @param {int} tab - column where the value has to be reset to '' ∈ {0, 1, 3}
     * @param {int} patho - criteria that has to be reset
     */
    resetRadiology(tab, patho) {
        const radio = this.state.radiology[tabs[tab]].slice()
        radio[patho] = new Array(7).fill('')
        this.setState(prevState => ({radiology: {...prevState.radiology, surgery: radio}}))
    }
    /**
     * Reset state values of a subtab of 'Radiology and surgery' to false.
     *
     * @param {int} tab - column where the value has to be reset to false ∈ {0, 1, 3}
     * @param {int} patho - criteria that has to be reset
     */
    initRadiology(tab, patho) {
        const radio = this.state.radiology[tabs[tab]].slice()
        radio[patho] = new Array(7).fill(false)
        this.setState(prevState => ({radiology: {...prevState.radiology, [tabs[tab]]: radio}}))
    }
    /**
     * Stores data related to whether or not the user clicked on a checkboxes of a subtab of 'Radiology and surgery'.
     *
     * @param {int} tab - column where the value has to be modified ∈ {0, 1, 2}
     * @param {int} patho - criteria that has to be modified
     * @param {int} disc - disc that has to be modified ∈ {0, 1, 2, 3, 4, 5, 6}
     */
    handleRadiology(tab, patho, disc) {
        const radio = this.state.radiology[tabs[tab]].slice()
        radio[patho].forEach((disque, keyDisque) => {
            let element = disque
            if (keyDisque === disc) {
                if (radio[patho][keyDisque] === "") element = true
                else element = !element
            }
            else if (radio[patho][keyDisque] === "") element = false
            radio[patho][keyDisque] = element
        })
        this.setState(prevState => ({radiology: {...prevState.radiology, [tabs[tab]]: radio}}))
    }

    /**
     * Stores data every time the user fills a field.
     */
    handleChange = e => {
        const names = ['age', 'height', 'weight', 'diabete', 'odi', 'work', 'employement']
        let name = e.target.name;
        let value = e.target.value;
        if (names.find(elt => elt === name)) value = parseInt(value)
        this.setState(prevstate => ({...prevstate, [name]: value }))
    };

    /**
     * Displays the component related to the step the user is at.
     *
     * @param {int} stepIndex - current step index
     */
    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <Identity
                    sex={this.state.sex}
                    age={this.state.age}
                    height={this.state.height}
                    weight={this.state.weight}
                    handleChange={this.handleChange}
                />
            case 1:
                return <MedicalHistory
                    smoke={this.state.smoke}
                    diabete={this.state.diabete}
                    lumbar_pain={this.state.lumbar_pain}
                    rad_pain={this.state.rad_pain}
                    radiculalgia={this.state.radiculalgia}
                    odi={this.state.odi}
                    handleChange={this.handleChange}
                    handleChangeCommited={(field, value) => this.setState({[field]: value})}
                />
            case 2:
                return <Work
                    work={this.state.work}
                    employement={this.state.employement}
                    handleChange={this.handleChange} />
            case 3:
                return <Surgery
                    step={0}
                    radiology_signs={this.state.radiology.radiology_signs}
                    surg_opinion={this.state.radiology.surg_opinion}
                    surgery={this.state.radiology.surgery}
                    surgery_details={this.state.radiology.surgery_details}
                    spine_op={this.state.radiology.spine_op}
                    spine_op_details={this.state.radiology.spine_op_details}
                    treatment={this.state.radiology.treatment}
                    treatment_details={this.state.radiology.treatment_details}
                    radio_normale={this.state.radiology.radio_normale}
                    radio={this.state.radiology.radio}
                    radio_details={this.state.radiology.radio_details}
                    initRadiology={(tab, patho) => this.initRadiology(tab, patho)}
                    resetRadiology={(tab, patho) => this.resetRadiology(tab, patho)}
                    handleRadiology={(tab, patho, disc) => this.handleRadiology(tab, patho, disc)}
                    handleTreatment={(treatment, value) => this.handleTreatment(treatment, value)}
                    handleDetails = {(tab, value) => this.handleDetails(tab, value)} />
            case 4:
                return <Surgery
                    step={1}
                    radiology_signs={this.state.radiology.radiology_signs}
                    surg_opinion={this.state.radiology.surg_opinion}
                    surgery={this.state.radiology.surgery}
                    surgery_details={this.state.radiology.surgery_details}
                    spine_op={this.state.radiology.spine_op}
                    spine_op_details={this.state.radiology.spine_op_details}
                    treatment={this.state.radiology.treatment}
                    treatment_details={this.state.radiology.treatment_details}
                    radio_normale={this.state.radiology.radio_normale}
                    radio={this.state.radiology.radio}
                    radio_details={this.state.radiology.radio_details}
                    initRadiology={(tab, patho) => this.initRadiology(tab, patho)}
                    resetRadiology={(tab, patho) => this.resetRadiology(tab, patho)}
                    handleRadiology={(tab, patho, disc) => this.handleRadiology(tab, patho, disc)}
                    handleTreatment={(treatment, value) => this.handleTreatment(treatment, value)}
                    handleDetails = {(tab, value) => this.handleDetails(tab, value)} />
            case 5:
                return <Surgery
                    step={2}
                    radiology_signs={this.state.radiology.radiology_signs}
                    surg_opinion={this.state.radiology.surg_opinion}
                    surgery={this.state.radiology.surgery}
                    surgery_details={this.state.radiology.surgery_details}
                    spine_op={this.state.radiology.spine_op}
                    spine_op_details={this.state.radiology.spine_op_details}
                    treatment={this.state.radiology.treatment}
                    treatment_details={this.state.radiology.treatment_details}
                    radio_normale={this.state.radiology.radio_normale}
                    radio={this.state.radiology.radio}
                    radio_details={this.state.radiology.radio_details}
                    initRadiology={(tab, patho) => this.initRadiology(tab, patho)}
                    resetRadiology={(tab, patho) => this.resetRadiology(tab, patho)}
                    handleRadiology={(tab, patho, disc) => this.handleRadiology(tab, patho, disc)}
                    handleTreatment={(treatment, value) => this.handleTreatment(treatment, value)}
                    handleDetails = {(tab, value) => this.handleDetails(tab, value)} />
            case 6:
                return <Surgery
                    step={3}
                    radiology_signs={this.state.radiology.radiology_signs}
                    surg_opinion={this.state.radiology.surg_opinion}
                    surgery={this.state.radiology.surgery}
                    surgery_details={this.state.radiology.surgery_details}
                    spine_op={this.state.radiology.spine_op}
                    spine_op_details={this.state.radiology.spine_op_details}
                    treatment={this.state.radiology.treatment}
                    treatment_details={this.state.radiology.treatment_details}
                    radio_normale={this.state.radiology.radio_normale}
                    radio={this.state.radiology.radio}
                    radio_details={this.state.radiology.radio_details}
                    initRadiology={(tab, patho) => this.initRadiology(tab, patho)}
                    resetRadiology={(tab, patho) => this.resetRadiology(tab, patho)}
                    handleRadiology={(tab, patho, disc) => this.handleRadiology(tab, patho, disc)}
                    handleTreatment={(treatment, value) => this.handleTreatment(treatment, value)}
                    handleDetails = {(tab, value) => this.handleDetails(tab, value)} />
            case 7:
                return <UploadImageStep
                    images={this.state.image_names}
                    dcm={this.state.dcm_files}
                    uploadImages={names => this.setState({image_names: names})}
                    uploadDicoms={dcm => this.setState({dcm_files: dcm})}/>
            case 8:
                return <Overview state={this.state}/>
            default:
                return <p>Unknown stepIndex</p>;
        }
    }

    /**
     * Stores patient elements into state and database at the end of the stepper.
     */
    handleFinish = () => {
        let selection = window.confirm('Are you sure you want to save this information?');
        if (selection) {
            this.setState({step: this.state.step+1})
            const data = {
                "label": this.state.label,
                "identity" : {
                    "sex": this.state.sex,
                    "age": this.state.age,
                    "height": this.state.height,
                    "weight": this.state.weight
                },
                "history" : {
                    "smoke": this.state.smoke,
                    "diabete": this.state.diabete,
                    "lumbar_pain": this.state.lumbar_pain,
                    "rad_pain": this.state.rad_pain,
                    "radiculalgia": this.state.radiculalgia,
                    "odi": this.state.odi
                },
                "work" : {
                    "work": this.state.work,
                    "employment": this.state.employement
                },
                "radio_surg" : get_surgery_values(this.state.radiology)
            }
            this.setState({clinical_data_finished: false})
            putCollectorData(data, value => this.setState({data_progress: value})).then(response => {
                if (response.status === 201) {
                    this.setState({clinical_data_finished: true})
                    if (this.state.dcm_files.length > 0) this.handlePostFiles(response.data.test)
                } else this.setState({finished: true, error_data: true})
            }).catch(() => this.setState({finished: true, error_data: true}))
        }
    }
    handleFilesStarted = (mod) => {
        const previous = this.state.files_finished
        previous[mod] = 0
        this.setState({files_finished: previous})
    }
    handleFilesFinished = (mod) => {
        const previous = this.state.files_finished
        previous[mod] = 1
        this.setState({files_finished: previous})
    }
    handleFilesProgress = (mod, progress) => {
        const previous = this.state.files_progress
        previous[mod] = progress
        if (progress === 100) this.handleFilesStarted(mod)
        this.setState({files_progress: previous})
    }

    handlePostFiles = (patient_id) => {
        this.state.dcm_files.forEach(folder => {
            const id = mod_list.findIndex(elt => elt === folder[1])+4
            postFile(patient_id, id, folder[0], progress => this.handleFilesProgress(id, progress))
                .then(response => {
                    if (response.status === 200) this.handleFilesFinished(id)
                }).catch(error => {
                    const errors = this.state.error_upload.slice()
                    errors.push(id)
                    this.handleFilesFinished(id)
                    this.setState({error_upload: errors})
            })
        })
    }

    isImageUploadFinished = () => {
        const nbr_mod = this.state.dcm_files.length
        let nbr_finished = 0
        Object.values(this.state.files_finished).forEach(elt => {
            if (elt === 1) nbr_finished += 1
        })
        return nbr_mod === nbr_finished
    }

    render() {
        const step = this.state.step
        const activeStep = (step >= 3 && step <= 6) ? 3 : step > 6 ? step - 3 : step
        return <div className='background-stepper'>
            <div className='navbar-stepper'>
                <Grid container>
                    <Grid item xs="auto" style={{display: 'flex'}}>
                        <Link to='/Home' id="link-home">
                            <NavBarBtn>Home</NavBarBtn>
                        </Link>
                    </Grid>
                    <Grid item xs style={{display: 'flex'}}>
                        <CMStepper
                            activeStep={activeStep}
                            steps={steps}
                        />
                    </Grid>
                    <Grid item xs="auto" style={{display: 'flex'}}>
                        <LogoutBlueBtn onClick={this.props.logout}>Logout</LogoutBlueBtn>
                    </Grid>
                </Grid>
            </div>
            {this.state.step === MAX_STEPS
                ? <div>
                    <ClinicalDataUpload
                        finished={this.state.clinical_data_finished}
                        error={this.state.error_data}
                    />
                    {this.state.dcm_files.length > 0 && <>
                        <br/><br/>
                        <p>Images upload{<FinishedSymbole finished={this.isImageUploadFinished()} error={this.state.error_upload.length > 0} />}</p>
                        <SuiviUploadModule
                            series={this.state.dcm_files}
                            files_progress={this.state.files_progress}
                            files_finished={this.state.files_finished}
                            error={this.state.error_upload}
                        />
                    </>}
                </div>
                : <>
                    <div className="items-clinical-data">
                        <FormControl>
                            {this.getStepContent(this.state.step)}
                        </FormControl>
                    </div>
                    <CMButtonStepper
                        step={this.state.step}
                        max_steps={MAX_STEPS}
                        handleBack={() => this.setState({step: this.state.step-1, error: false})}
                        handleNext={() => this.setState({step: this.state.step+1})}
                        handleFinish={this.handleFinish}
                    />
                </>}
        </div>
    }
}