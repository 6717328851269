import React from "react";
import './Stepper.css'
import {withStyles} from "@material-ui/core";
import {Button, Step, StepLabel, Stepper} from "@mui/material";

const styles = (theme) => ({
    root: {
        color: "rgba(19, 55, 90, 0.3)",
        '&$active': {
            //color: "rgba(19, 55, 90, 0.6)",
            color: "rgba(215, 174, 103, 1)",

        },
        '&$completed': {
            color: "rgba(19, 55, 90, 0.9)",
        },
    },
    active: {},
    completed: {},
});

export const CMButtonStepper = ({step, max_steps, handleBack, handleNext, handleFinish}) => {
    return <div className='next-buttons'>
        <Button
            id={step === 0 ? "back-button-disabled" : "back-button"}
            disabled={step === 0}
            onClick={handleBack}
        >
            Back
        </Button>
        <Button
            id="next-button"
            variant="contained"
            onClick={step === max_steps - 1 ? handleFinish : handleNext}
        >
            {step === max_steps - 1 ? 'Finish' : 'Next'}
        </Button>
    </div>
}

const CMStepper = ({classes, steps, activeStep}) => {
    return <Stepper id='stepper' activeStep={activeStep} alternativeLabel>
        {steps.map((label, keyLabel) => (
            <Step key={keyLabel}>
                <StepLabel
                    StepIconProps={{
                        classes: {
                            root: classes.root,
                            active: classes.active,
                            completed: classes.completed
                        }
                    }}
                >
                    {label}
                </StepLabel>
            </Step>
        ))}
    </Stepper>
}

export default withStyles(styles)(CMStepper)