import React from 'react'
import './Home.css'
import {Redirect} from 'react-router-dom'
import {getListofPatients, getModalities,} from '../../services/CollectorService'
import {GridPatient} from "./GridPatient";
import {Backdrop, CircularProgress, Grid, Paper} from "@mui/material";
import {DetailsPatient} from "./DetailsPatient";
import {NavBar} from "../NavBar/NavBar";

const first_titles = ['Sex', 'Age (yo)', 'Height (cm)', 'Weight (kg)']
const titles_large = ['Smoke', 'Diabete', 'Lumbar pain', 'Radicular pain', 'Radiculalgia side', 'ODI']
const titles_medium = ['Smoke', 'Diabete']

const widths = {
    'large': [2, 4, 4, 2],
    'medium': [3, 4, 3, 2],
    'small' : [4, 6, "auto", 2],
    'xsmall': [5, 7, "auto", "auto"]
}

const HomeTabTitles = ({win_size}) => {
    return <Grid container className='home-table-titles'>
        <Grid item xs={widths[win_size][0]} style={{display: 'flex'}}><p style={{margin: 'auto'}}>Patient</p></Grid>
        <Grid item xs={widths[win_size][1]}>
            <Grid container>
                {first_titles.map((col, keyCol) => {
                    return <Grid item xs={3} key={keyCol} style={{display: 'flex'}}><p style={{margin: 'auto'}}>{col}</p></Grid>
                })}
            </Grid>
        </Grid>
        {(win_size === 'medium' || win_size === 'large') && <Grid item xs={widths[win_size][2]} style={{display: 'flex'}}>
            <Grid container>
                {win_size === 'large'
                    ? titles_large.map((col, keyCol) => {
                        return <Grid item xs={2} key={keyCol} style={{display: 'flex'}}><p style={{margin: 'auto'}}>{col}</p></Grid>
                    })
                    : titles_medium.map((col, keyCol) => {
                        return <Grid item xs={6} key={keyCol} style={{display: 'flex'}}><p style={{margin: 'auto'}}>{col}</p></Grid>
                    })
                }
            </Grid>
        </Grid>
        }
        {win_size !== 'xsmall' && <Grid item xs={widths[win_size][3]} style={{display: 'flex'}}>
            <p style={{margin: 'auto'}}>Series</p>
        </Grid>}
    </Grid>
}

const getWinSize = () => {
    const win = window.innerWidth
    if (win <= 430) return 'xsmall'
    else if (win <= 650) return 'small'
    else if (win <= 1000) return 'medium'
    else return 'large'
}

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            see_more: false,
            patients: [],
            patients_ready: false,
            no_patients: false,
            modalities: [],
            modalities_ready: false,

            add_images: false,
            add_images_patient: -1,
            update_patient: false,
            update_patient_patient: -1,

            backdrop_patient: -1,

            small_win: window.innerWidth <= 1000,
            win_size: getWinSize()
        }
    }

    updateWindowSize = () => {
        this.setState({small_win: window.innerWidth <= 1000})
        this.setState({win_size: getWinSize()})
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowSize)
        getListofPatients().then(response => {
            this.setState({patients: response.data, patients_ready: true, no_patients: response.data.length === 0})
            return response.data
        }).then(data => {
            for (let j = 0; j < data.length; j++) {
                getModalities(data[j].patient.id).then(response => {
                    const modalities = []
                    for (let i = 0; i < response.data.length; i++) {
                        modalities.push(response.data[i].modality)
                    }
                    const modalities_patient = this.state.modalities.slice()
                    modalities_patient[j] = modalities
                    this.setState({modalities: modalities_patient})
                })
            }
        })

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize)
    }

    render() {
        if (this.state.add_images) return <Redirect
            to={{
                pathname: '/AddImages',
                state: {'id': this.state.patients[this.state.add_images_patient]['patient']['id']}
            }}
        />
        else if (this.state.update_patient) {
            const patient = this.state.patients[this.state.update_patient_patient]['patient']
            return <Redirect
                to={{
                    pathname: '/UpdatePatient',
                    state: {
                        'id': patient['id'],
                        'identity': patient['clinical_data']['identity'],
                        'history': patient['clinical_data']['med_history'],
                        'work': patient['clinical_data']['daily_life']
                    }
                }}
            />
        } else return <div className='home-page'>
            <NavBar
                win_size={this.state.win_size}
                username={this.props.name}
                handleLogout={this.props.logout}
            />
            <Paper id="home-table">
                <HomeTabTitles win_size={this.state.win_size}/>
                {this.state.patients_ready //&& this.state.modalities_ready
                    ? <div className='home-container'>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={this.state.backdrop_patient >= 0}
                            onClick={() => this.setState({backdrop_patient: -1})}
                        >
                            {this.state.backdrop_patient >= 0 && <DetailsPatient id={this.state.backdrop_patient}/>}
                        </Backdrop>
                        {this.state.patients.length > 0 && !this.state.no_patients
                            ? this.state.patients.map((patient, keyPatient) => {
                                const data = patient['patient']['clinical_data']
                                return <GridPatient
                                    key={keyPatient}
                                    small_win={this.state.small_win}
                                    win_size={this.state.win_size}
                                    widths={widths[this.state.win_size]}
                                    color={keyPatient%2 === 0 ?'var(--medium-gold)' : 'var(--light-gold)'}
                                    name={patient['patient']['label']}
                                    identity={data['identity']}
                                    med_history={data['med_history']}
                                    images={this.state.modalities[keyPatient]}
                                    handleAddImages={() => this.setState({add_images: true, add_images_patient: keyPatient})}
                                    handleUpdatePatient={() => this.setState({update_patient: true, update_patient_patient: keyPatient})}
                                    handleClickPatient={() => this.setState({backdrop_patient: patient['patient']['id']})}
                                />
                            })
                            : this.state.no_patients
                                ? <p>No patients</p>
                                : <div style={{margin: 'auto', maxWidth: 'fit-content'}}>
                                    <CircularProgress style={{color: 'var(--gold)'}}/>
                                </div>
                        }
                    </div>
                    : <div style={{margin: 'auto', maxWidth: 'fit-content'}}>
                        <CircularProgress style={{color: 'var(--gold)'}}/>
                    </div>
                }
            </Paper>
        </div>
    }
}