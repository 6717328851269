export const steps = [
    'Patient data',
    'Medical history',
    'Daily life',
    'Radiology and surgery',
    'Upload images',
    'Overview'
]
export const MAX_STEPS = 9

export const modalities = ['SagT1', 'SagT2', 'AxT1', 'AxT2', "SagT1w",
    "SagT2w",
    "SagT2 STIR",
    "Sag Flair",
    "Sag DIXON w",
    "Sag DIXON f",
    "Sag DIXON in",
    "Sag DIXON opp",
    "SagT2 3D",
    "AxT1w",
    "AxT2w",
    "AxT2 3D",
    "Coronal T2 STIR",
    "Unknown"]

export const works = ['Manual handling of loads', 'Painful postures/repetitive movements', 'Work subjected to mechanical vibrations', 'Office work', 'Other']
export const employements = ['Self-employed', 'Employee', 'Unemployed', 'Retired']

export const vb = ['T11/T12', 'T12/L1', 'L1/L2', 'L2/L3', 'L3/L4', 'L4/L5', 'L5/S1']
export const dbVb = ["t11_t12", "t12_l1", "l1_l2", "l2_l3", "l3_l4", "l4_l5", "l5_s1"]

export const valuesSurgery = ["Hernia removal", "Laminectomy and recalibration", "Lumbar arthrodesis", "Infiltration", "Other"]
export const dbSurgery = ["hernie", "laminectomie", "arthrodese", "infiltration", "other"]
export const valuesOperation = ["Hernia removal", "Laminectomy and recalibration", "Lumbar arthrodesis", "Other"]
export const dbSpineOperation = ["hernie", "laminectomie", "arthrodese", "other"]
export const valuesRadiographie = ["Scoliosis", "Transitional abnormality", "Spondylolisthesis"]
export const dbRadiographie = ["scoliose", "anomalie", "spondylolisthesis"]
export const valuesTreatment = ["Non-morphine analgesic", "Morphine analgesic", "Anti-inflammatory (NSAIDs & corticosteroids)", "Infiltration", "Neurotropic treatment", "Physiotherapy", "Other"]
export const valuesAll = [valuesSurgery, valuesOperation, valuesTreatment, valuesRadiographie]

export const surgeryTabs = ["Surgery indication/infiltration", "Previous spine operation", "Treatment", "Standard x-ray"] //used for the names of the tabs only

export const testValuesRadioSurgery = [
    //surgery
    [
        [false, false, true, true, false, false, true],
        [false, false, false, true, false, false, true],
        [false, true, true, true, false, false, true],
        [false, false, true, true, false, false, true],
        [true, false, true, true, false, false, true]],
    //spine_op
    [
        [false, false, false, true, false, false, true],
        [true, false, true, true, false, false, true],
        [false, false, true, true, false, false, true],
        [false, false, true, true, false, false, true]
    ],
    //radio
    [
        [false, true, true, true, false, false, true],
        [false, false, true, true, false, false, true],
        [true, false, true, true, false, false, true],
        [false, false, false, false, false, false, false],

    ],
]