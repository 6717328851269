import React from "react";
import {Redirect, useLocation} from "react-router-dom";
import {NavBar} from "../NavBar/NavBar";
import './UpdatePatientPage.css'
import UpdatePatient from "./UpdatePatient";

export const UpdatePatientPageHistory = props => {
    const state = useLocation().state
    const patient_id = state !== undefined ? state.id : null
    const identity = state !== undefined ? state.identity : null
    const history = state !== undefined ? state.history : null
    const work = state !== undefined ? state.work : null
    return <UpdatePatientPage {...props} patient_id={patient_id} identity={identity} history={history} work={work}/>
}

const UpdatePatientPage = ({name, logout, patient_id, identity, history, work}) => {
    if (patient_id === null) return <Redirect to={{pathname: '/Home'}}/>
    else return <div className='update-patient-page'>
        <NavBar
            username={name}
            handleLogout={logout}
        />
        <div className='update-patient-page-section'>
            <h1>Update patient {patient_id} information</h1>
            <UpdatePatient
                patient_id={patient_id}
                identity={identity}
                history={history}
                work={work}
            />
        </div>
    </div>
}