import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";

export const sex_choices = ["NA", "W", "M"]

export const IdentityComp = ({value, handleChange}) => {
    return <div className='identity-element'>
        <p>Sex</p>
        <div className='sex'>
            <RadioGroup
                name="sex"
                value={value}
                onChange={handleChange}
            >
                <div className='sex'>
                    {sex_choices.map((option, keyOption) => {
                        return <FormControlLabel
                            key={keyOption}
                            style={{margin: 0, padding: "2px"}}
                            value={option}
                            control={<Radio size="small" style={{color: 'var(--dark-blue)', padding: 0}} />}
                            label={option}
                        />
                    })}
                </div>
            </RadioGroup>
        </div>
    </div>
}

/**
 * Displays the fields related to patient identity (step 0).
 *
 * @param {boolean} error_message
 * @param {String} label - short description to recognize patient in database
 * @param {String} sex ∈ {'F', 'M', 'NA'}
 * @param {int} age ∈ {0, 1, ..., 130}
 * @param {int} height ∈ {0, 1, ..., 250}
 * @param {int} weight ∈ {0, 1, ..., 400}
 * @param {function} handleChange
 * @param {function} handleBlur - for input elements age, height and weight
 *
 * @returns {JSX.Element}
 */
export const Identity = ({sex, age, height, weight, handleChange}) => {

    return <div className='identity'>
        <IdentityComp
            value={sex}
            handleChange={handleChange}
        />
        <div className='identity-element'>
            <p>Age</p>
            <input type="number" min="0" name="age" value={age >= 0 ? age : ''} onChange={handleChange}/>
            <br/>
            <br/>
            <p>Height (cm)</p>
            <input type="number" min="0" name="height" value={height >= 0 ? height : ''} onChange={handleChange}/>
            <br/>
            <br/>
            <p>Weight (kg)</p>
            <input type="number" min="0" name="weight" value={weight >= 0 ? weight : ''} onChange={handleChange}/>
        </div>
    </div>
}