import React from "react";
import {Redirect} from "react-router-dom";
import {ErrorMessage} from "../Utils/ErrorManagement";
import {steps} from "../Utils/Items";
import {Grid} from "@mui/material";
import CMStepper, {CMButtonStepper} from "../Utils/Stepper";
import {Identity} from "../NewPatient/Steps/IdentityStep";
import {MedicalHistory} from "../NewPatient/Steps/MedHistoryStep";
import {Work} from "../NewPatient/Steps/WorkStep";
import {updateIdentity} from "../../services/UpdateService";

export default class UpdatePatient extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 0,

            identity: this.props.identity,
            history: this.props.history,
            work: this.props.work,

            finished: 0,

            small_win: window.innerWidth <= 1000
        }
    }

    handleIdentityChange = e => {
        const new_val = e.target.name === 'sex' ? e.target.value : parseInt(e.target.value)
        this.setState(prevstate => ({...prevstate, identity: {...prevstate.identity, [e.target.name]: new_val}}))
    }

    handleHistoryChange = (name, value) => {
        let new_val = value
        if (name === 'diabete' || name === 'odi' || name === 'lumbar_pain' || name === 'rad_pain') new_val = parseInt(new_val)
        this.setState(prevstate => ({...prevstate, history: {...prevstate.history, [name]: new_val}}))
    }

    handleWorkChange = (name, value) => {
        if (name === 'employement') this.setState(prevstate => ({...prevstate, work: {...prevstate.work, employment: value}}))
        else this.setState(prevstate => ({...prevstate, work: {...prevstate.work, work: value}}))
    }

    handleFinish = () => {
        let selection = window.confirm('Are you sure you want to save this information?');
        if (selection) {
            const data = {
                identity: this.state.identity,
                med_history: this.state.history,
                work: this.state.work
            }
            updateIdentity(this.props.patient_id, data)
                .then(response => {
                    if (response.status === 200) this.setState({finished: 1})
                    else this.setState({finished: -1})
                })
                .catch(error => this.setState({finished: -1}))
        }
    }

    render() {
        if (this.state.finished === 1) return <Redirect to={{pathname: '/Home'}}/>
        else if (this.state.finished === -1) return <ErrorMessage
            title={` - patient ${this.props.patient_id}`}
            message="An error occurred while trying to update the patient"
            strong="try again or contact the support"
        />
        else {
            const {sex, age, height, weight} = this.state.identity
            const {smoke, diabete, lumbar_pain, rad_pain, radiculalgia, odi} = this.state.history
            return <div className='update-patient'>
                <Grid container className='update-patient-stepper'>
                    <Grid item xs={12}>
                        <CMStepper
                            steps={steps.slice(0,3)}
                            activeStep={this.state.step}
                        />
                    </Grid>
                </Grid>
                <Grid container className='update-patient-content'>
                    <Grid item xs={12}>
                        {this.state.step === 0
                            ? <Identity
                                sex={sex}
                                age={age}
                                height={height}
                                weight={weight}
                                handleChange={this.handleIdentityChange}
                            />
                            : this.state.step === 1
                                ? <MedicalHistory
                                    smoke={smoke}
                                    diabete={diabete}
                                    lumbar_pain={lumbar_pain}
                                    rad_pain={rad_pain}
                                    radiculalgia={radiculalgia}
                                    odi={odi}
                                    handleChange={e => this.handleHistoryChange(e.target.name, e.target.value)}
                                    handleChangeCommited={this.handleHistoryChange}
                                />
                                : <Work
                                    work={this.state.work.work}
                                    employement={this.state.work.employment}
                                    handleChange={e => this.handleWorkChange(e.target.name, parseInt(e.target.value))}
                                />
                        }
                    </Grid>
                </Grid>
                <Grid container className='update-patient-btnstepper'>
                    <Grid item xs={12} style={{display: 'flex'}}>
                        <CMButtonStepper
                            step={this.state.step}
                            max_steps={3}
                            handleBack={() => this.setState({step: this.state.step-1})}
                            handleNext={() => this.setState({step: this.state.step+1})}
                            handleFinish={this.handleFinish}
                        />
                    </Grid>
                </Grid>
            </div>
        }
    }
}