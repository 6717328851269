import React from "react"
import './Overview.css'
import {employements, valuesTreatment, valuesOperation, valuesRadiographie, valuesSurgery, works} from "../../Utils/Items";
import {GridSample, GridSampleImages, GridSampleRadiology} from "./GridSamples";
import {Divider} from "@mui/material";

/**
 * Returns, for each category of data, true if there are values to be displayed in the right column, false otherwise
 * @param values {Array} - list of values to be inserted in the overview for the chosen category
 * @return {boolean}
 */
export const getValue = (values) => {
    return !!values.find(elt => elt.includes(true) || elt.includes(false))
}

export const Overview = ({state}) => {
    return <div className='overview'>
        <img src='images/logo_caerus.png' alt='logo_caerus' />
        <div id='separation'/>
        <h3>Patient data</h3>
        <GridSample
            titles={['Label', 'Sex', 'Age', 'Height', 'Weight']}
            names={['label', 'sex', 'age', 'height', 'weight']}
            state={state}
            unities={[' ', ' ',' years old',' cm',' kg']}
        />
        <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
        <h3>Medical history</h3>
        <GridSample
            titles={['Smoke','Diabete','Lumbar pain','Radicular pain', 'Radiculalgia side', 'Oswestry Disability Index']}
            names={['smoke','diabete','lumbar_pain','rad_pain', 'radiculalgia', 'odi']}
            state={state}
            unities={[' ', ' ', '/10', '/10', ' ', '/100']}
        />
        <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
        <h3>Daily life</h3>
        <GridSample
            titles={['Work','Employement']}
            state={[state.work >= 0 ? works[state.work] : 'NA', state.employement >= 0 ? employements[state.employement] : 'NA']}
            unities={[' ', ' ']}
        />
        <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
        <h3>Radiology and surgery</h3>
        {state.radiology.radiology_signs && <GridSample
            titles={['Main radiological sign']}
            state={[state.radiology.radiology_signs]}
            unities={[' ']}
        />}

        <GridSample
            subtitle={"Surgery indication/infiltration"}
            titles={['Surgical opinion required?']}
            state={[state.radiology.surg_opinion !== '' ? state.radiology.surg_opinion : 'NA']}
            unities={[' ']}
        />
        {getValue(state.radiology.surgery) && <GridSampleRadiology
            subtitles={valuesSurgery}
            state={state.radiology.surgery}
        />}
        {state.radiology.surgery_details !== '' && <GridSample
            titles={['Details']}
            state={[state.radiology.surgery_details]}
            unities={[' ']}
        />}


        {getValue(state.radiology.spine_op) && <GridSampleRadiology
            title="Previous spine operation"
            subtitles={valuesOperation}
            state={state.radiology.spine_op}
        />}
        {state.radiology.spine_op_details !== '' && <GridSample
            subtitle={getValue(state.radiology.spine_op) ? null : "Previous spine operation"}
            titles={['Details']}
            state={[state.radiology.spine_op_details]}
            unities={[' ']}
        />}


        <GridSample
            subtitle="Treatment"
            titles={valuesTreatment}
            state={state.radiology.treatment}
            unities={['', '', '', '', '', '', '']}
        />
        {state.radiology.treatment_details !== '' && <GridSample
            titles={['Details']}
            state={[state.radiology.treatment_details]}
            unities={[' ']}
        />}

        <GridSample
            subtitle={"Standard x-ray"}
            titles={['Normal x-ray?']}
            state={[state.radiology.radio_normale ? "Yes" : "No"]}
            unities={[' ']}
        />
        {getValue(state.radiology.radio) && <GridSampleRadiology
            subtitles={valuesRadiographie}
            state={state.radiology.radio}
        />}
        {state.radiology.radio_details !== '' && <GridSample
            subtitle={null}
            titles={['Details']}
            state={[state.radiology.radio_details]}
            unities={[' ']}
        />}

        <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
        <h3>Images uploaded</h3>
        <GridSampleImages state={state.dcm_files}/>
    </div>
}