import {Grid} from "@mui/material"
import {Redirect} from "react-router-dom"
import React from "react"
import './NavBar.css'
import {LogoutBlueBtn, NavBarBtn} from "../Utils/CustomButtons";

const NavBarButton = ({link, label}) => {
    const [redirect, setRedirect] = React.useState(false)

    if (redirect) return <Redirect to={{pathname: link}}/>
    else return <Grid item xs="auto" style={{display: 'flex'}}>
        <NavBarBtn onClick={() => setRedirect(true)}>{label}</NavBarBtn>
    </Grid>
}

export const NavBar = ({win_size, username, handleLogout}) => {
    const currPage = window.location.pathname.replace('/', '')
    const title_size = win_size === 'xsmall' ? '20px' : win_size === 'small' ? '26px' : '32px'
    return <Grid container className='navbar'>
        <Grid item xs="auto" style={{display: 'flex'}}>
            <h1 style={{margin: 'auto 10px', width: 'fit-content', 'fontSize': title_size}}>Welcome {username}</h1>
        </Grid>
        {currPage !== 'Home' && <NavBarButton link='/Home' label='Home'/>}
        <NavBarButton link='/NewPatient' label='Add new patient'/>
        <Grid item xs />
        <Grid item xs="auto" style={{display: 'flex'}}>
            <LogoutBlueBtn onClick={handleLogout}>Logout</LogoutBlueBtn>
        </Grid>
    </Grid>
}