import React from 'react'
import {LinearProgress, Stack} from "@mui/material";
import {FinishedSymbole} from "../../NewPatient/utils";

export const ProgressBarModule = ({nbr_images, finished}) => {
    const [progress, setProgress] = React.useState(0)
    const temps_total = Math.trunc(0.8*nbr_images)+1

    React.useEffect(() => {
        if (progress <= temps_total) {
            const timer = setInterval(() => {
                if (finished !== 0) setProgress(temps_total+1)
                else setProgress((oldProgress) => {return Math.min(oldProgress + 1)})
            }, 1000)
            return () => {
                clearInterval(timer)
            }
        }
    }, [progress, setProgress, finished, nbr_images, temps_total])

    return <Stack sx={{color: 'var(--dark-blue)', margin: '10px auto'}}>
        <p style={{color: "var(--dark-blue)", marginBottom: "5px"}}>
            Saving files data<FinishedSymbole finished={finished && progress > temps_total} error={false} />
        </p>
        <LinearProgress color="inherit" variant="determinate" value={progress <= temps_total ? (progress*100)/temps_total : 100}/>
    </Stack>
}