import React from 'react'
import './App.css'
import LoginPage from './components/LoginPage/LoginPage'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import {get, loginService, startSession} from "./services/UserService"
import ClinicalData from "./components/NewPatient/ClinicalData"
import Home from "./components/Home/Home"
import SessionTimer from "./sessionTimer"
import {AddImagesPageHistory} from "./components/AddImages/AddImagesPage";
import {UpdatePatientPageHistory} from "./components/UpdatePatient/UpdatePatientPage";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logged_in: !!sessionStorage.getItem('token'),
            data: {
                first_name: '',
                last_name: '',
                is_surgeon: true,
                id: -1,
            },
            loading: false,
            fetch_error: false,
            other_session_detected: false,
            wrong_credentials: false,
            password_missing: false,
            username_missing: false,
        }
    }

    componentDidMount() {
        if (this.state.logged_in && this.state.data.first_name ==='') {
            get()
                .then( json => {
                    if (json.data.id) {
                        this.setState({
                            logged_in: true,
                            data: {
                                first_name: json.data.first_name,
                                last_name: json.data.last_name,
                                is_surgeon: !json.data.is_superuser,
                                id: json.data.id
                            },
                            wrong_credentials: false,
                            password_missing: false,
                            username_missing: false,
                        })
                    } else this.logout()
                })
                .catch(() => this.logout())

        } else this.setState({})
    }

    updateLoginFails = (other_session, wrong_cred, pass_missing, name_missing) => {
        this.setState({
            other_session_detected: other_session,
            wrong_credentials: wrong_cred,
            password_missing: pass_missing,
            username_missing: name_missing
        })
    }

    handleLogin = (e, data) => {
        e.preventDefault()
        this.setState({loading: true, fetch_error: false})
        if (data.username && data.password) {
            loginService(data)
                .then(json => {
                    this.setState({loading: false})
                    if (json.token && json.user) return json
                    else {
                        this.updateLoginFails(false, true, false, false)
                        return null
                    }
                })
                .then(json => {
                    if (json) {
                        startSession(json)
                            .then(response => {
                                if (response.ok) {
                                    sessionStorage.setItem('token', json.token)
                                    window.location.reload()
                                } else this.setState({other_session_detected: true})
                            })
                    }
                })
                .catch(error => this.setState({fetch_error: true}))
        } else this.updateLoginFails(false, false, !data.password, !data.username)
    };

    handleLogout = () => {
        let selection = window.confirm('Do you really want to log out?');
        if (selection) this.logout()
    }

    logout = () => {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('timer')
        this.setState({
            logged_in: false,
            data: {
                username: '',
                first_name: '',
                last_name: '',
                is_surgeon: false,
                surgeon_id: -1,
            },
            other_session_detected: false,
            wrong_credentials: false,
            password_missing: false,
            username_missing: false,
        })
        return <Redirect to='/login' />;
    }

    render () {
        const condition = this.state.data.first_name && this.state.data.last_name && this.state.data.first_name !== '' && this.state.data.last_name !== ''
        const name = condition ? this.state.data.first_name+' '+this.state.data.last_name : ''
        return <Router>
            {(sessionStorage.getItem('token') && this.state.logged_in) ?
                <div className='app'>
                    <SessionTimer start={this.state.logged_in} logout={this.logout}/>
                    <div className='app-content'>
                        <Switch>
                            <ProtectedRoute exact path='/NewPatient'>
                                <ClinicalData logout={this.handleLogout} />
                            </ProtectedRoute>
                            <ProtectedRoute exact path='/AddImages'>
                                <AddImagesPageHistory name={name} logout={this.handleLogout} />
                            </ProtectedRoute>
                            <ProtectedRoute exact path='/UpdatePatient'>
                                <UpdatePatientPageHistory name={name} logout={this.handleLogout} />
                            </ProtectedRoute>
                            <ProtectedRoute exact path='/Home'>
                                <Home name={name} logout={this.handleLogout}/>
                            </ProtectedRoute>
                            <Route exact path='/'>
                                <Redirect exact from='/' to='/Home' />
                            </Route>
                            <Route path='*'>
                                <Redirect from='/' to='/Home' />
                            </Route>
                        </Switch>
                    </div>
                    <div className='footer'>
                        <p>Use of this part of the Caerus Medical website is strictly restricted to care givers working with Caerus Medical.</p>
                    </div>
                </div>
                : <Switch>
                    <Route path='/login'>
                        <LoginPage
                            loading={this.state.loading}
                            fetch_error={this.state.fetch_error}
                            handle_login={(e,data) => this.handleLogin(e,data)}
                            other_session_detected={this.state.other_session_detected}
                            wrong_credentials={this.state.wrong_credentials}
                            password_missing={this.state.password_missing}
                            username_missing={this.state.username_missing}/>
                    </Route>
                    <Route path='*'>
                        <Redirect to='/login' />
                    </Route>
                </Switch>}
        </Router>
    }
}

export default App