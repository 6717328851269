import {Alert, AlertTitle} from "@mui/material";
import React from "react";

export const ErrorMessage = ({title, message, strong}) => {
    return <Alert severity="error" style={{minWidth: '600px', width: 'fit-content', margin: '5px auto', backgroundColor: 'var(--light-error-color)'}}>
        <AlertTitle>Error {title}</AlertTitle>
        {message} — <strong>{strong}</strong>
    </Alert>
}

export const SuccessMessage = ({title, message, strong}) => {
    return <Alert severity="success" style={{minWidth: '600px', width: 'fit-content', margin: '5px auto', backgroundColor: 'var(--light-success-color)'}}>
        <AlertTitle>Success {title}</AlertTitle>
        {message} — <strong>{strong}</strong>
    </Alert>

}