import { makeStyles } from "@material-ui/core"
import React from "react"
import './Steps.css'

export const useStyles = makeStyles({
    tabs: {
        color: "rgb(19, 55, 90)",
        "& .MuiTabs-indicator": {
            backgroundColor: "rgb(19, 55, 90)"
        }
    }
});

/**
 * Displays an error message in red below the field that needs to be fill in.
 *
 * @returns {JSX.Element}
 */
export const error = () => <p style={{color: "red", fontSize: '10px'}}>You need to fill this field</p>

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        },
    },
};