import {Grid} from "@material-ui/core";
import {vb} from "../../Utils/Items";
import React from "react";

/**
 * Displays the grid for each category of data except radiology/surgery ones
 * @param props - dictionary of parameters needed :
 *          - subtitle: red paragraph (only used for treatment) -- Previous treatment
 *          - titles: column on the left -- Height
 *          - names: name of the category stored in state -- height
 *          - state: data stored in state, to be retrieved for each name from names -- 163
 *          - unities: not stored but needed to be displayed -- cm
 * @return {JSX.Element}
 */
export const GridSample = (props) => {
    return <>
        <Grid container>
            <Grid item xs={6}>
                {props.subtitle && <h5 className='overview-titles'>{props.subtitle}</h5>}
            </Grid>
            <Grid item xs={6}/>
        </Grid>
        <Grid container>
            <Grid item xs={6}>
                {props.titles.map((elt, key) => <p key={key} className='overview-subtitles'>{elt}</p>)}
            </Grid>
            <Grid item xs={6}>
                {props.names
                    ? props.names.map((elt, key) => {
                        let value = ''
                        if (props.state[elt] !== -1 && props.state[elt] !== '' && props.state[elt] !== 'NA') value = props.state[elt]+props.unities[key]
                        else value = 'NA'

                        if (elt === 'diabete') {
                            if (props.state[elt] === 0) value = 'No'
                            else if (props.state[elt] === 1) value = 'Type 1'
                            else if (props.state[elt] === 2) value = 'Type 2'
                            else value = 'NA'
                        }
                        return <p key={key} className='overview-values'>{value}</p>
                    })
                    : props.state.map((elt, key) => {
                        return <p key={key} className='overview-values'>{elt}</p>
                    })
                }
            </Grid>
        </Grid>
    </>
}

function get_ivd_list(state) {
    let ivd_list = ''
    state.forEach((value, keyValue) => {
        if (value) ivd_list = ivd_list.concat(' ', vb[keyValue])
    })
    return ivd_list
}

/**
 * Displays the grid for radiology/surgery categories
 *
 * @param title {string} - red title on the left column --- Previous treatment
 * @param subtitles {Array[string]} - blue subtitle on the left column, under the red title
 * @param state {Array[Array[boolean]]} - ivd list, for each criterion (such as Spondylolisthesis) : true if the ivd has been selected, false otherwise
 *
 */
export const GridSampleRadiology = ({title, subtitles, state}) => {
    return <>
        <Grid container>
            <Grid item xs={6}>
                {title && <h5 className='overview-titles'>{title}</h5>}
            </Grid>
            <Grid item xs={6}/>
        </Grid>
        <Grid container>
            {subtitles.map((disease, keyDisease) => {
                if (!state[keyDisease].includes('')) return <Grid key={keyDisease} container>
                    <Grid item xs={6}><p className='overview-subtitles'>{disease}</p></Grid>
                    {state[keyDisease].includes(true)
                        ? <Grid item xs={6}><p className='overview-values'>{get_ivd_list(state[keyDisease])}</p></Grid>
                        : <Grid item xs={6}/>
                    }
                </Grid>
                else return null
            })}
        </Grid>
    </>
}

/**
 * Displays the grid for images
 *
 */
export const GridSampleImages = ({state}) => {
    return <>
        {state.map((modality, keyModality) => {
            return <Grid container key={keyModality}>
                <Grid item xs={6}>
                    <p style={{color: "rgb(19, 55, 90)", textAlign: 'end', marginRight: '10px'}}>{modality[1]}</p>
                </Grid>
                <Grid item xs={6}>
                    {modality[0].map((image, keyImage) => {
                        return <p key={keyImage} style={{color: "#D7AE67", textAlign: 'start'}}>{image.name}</p>
                    })}
                </Grid>
            </Grid>
        })}
    </>
}

