import React from 'react'
import {updateSession} from "./services/UserService";

export default function SessionTimer(props) {
    const [progress, setProgress] = React.useState(0)

    React.useEffect(() => {
        const timer = setInterval(async () => {
            if (props.start === true && progress >= 0) setProgress(prevProgress => (prevProgress + 1))
            if (progress === 5) updateSession()
                .then(response => setProgress(0))
                .catch(error => props.logout())
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [progress, setProgress, props]);

    return (
        <></>
    );
}