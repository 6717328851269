import {Grid} from "@mui/material";

export const ListOfFilesModule = ({dcm, handleDeleteMod, handleDeleteLine}) => <div className='upload-image-tabs'>
    <p>Files uploaded</p>
    <div className='test'>
        {dcm.map((modality, keyModality) => {
            return <Grid container key={keyModality}>
                <Grid item xs={6}>
                    <p style={{color: "rgb(19, 55, 90)", textAlign: 'end', marginRight: '10px'}}>
                        <i
                            className="fas fa-minus-circle" style={{cursor: "pointer"}}
                            onClick={() => handleDeleteMod(keyModality)}
                        />
                        {modality[1]}
                    </p>
                </Grid>
                <Grid item xs={6}>
                    {modality[0].map((dcm, keyImage) => {
                        return <p key={keyModality + '-' + keyImage}
                                  style={{color: "#D7AE67", textAlign: 'start'}}>{dcm.name}
                            <i
                                className="fas fa-minus-circle" style={{cursor: "pointer"}}
                                onClick={() => handleDeleteLine(keyModality, keyImage)}
                            />
                        </p>
                    })}
                </Grid>
            </Grid>
        })}
    </div>
</div>