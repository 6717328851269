import {withStyles} from "@material-ui/core/styles";
import {Button} from "@mui/material";
import React from "react";

export const NavBarBtn = withStyles({
    root: {
        margin: 'auto 10px',
        backgroundColor: 'rgba(215, 174, 103, 0.6)',
        border : '2px solid rgba(215, 174, 103, 0.6)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid rgba(215, 174, 103, 0.6)',
        },
        color: "black"
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const LogoutBlueBtn = withStyles({
    root: {
        margin: 'auto 10px',
        backgroundColor: 'var(--dark-blue)',
        color: 'white',
        width: 'fit-content',
        border : '2px solid var(--dark-blue)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--dark-blue)',
            color: 'var(--dark-blue)'
        },
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const CancelRedBtn = withStyles({
    root: {
        margin: '10px auto',
        backgroundColor: 'crimson',
        color: 'white',
        width: 'fit-content',
        border : '2px solid crimson',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid crimson',
            color: 'crimson'
        },
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);