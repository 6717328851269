import React from "react";
import './DetailsPatient.css'
import {CircularProgress, Divider, Grid} from "@mui/material";
import {getPatientDetails} from "../../services/CollectorService";
import {GridSample} from "../NewPatient/Overview/GridSamples";
import {valuesOperation, valuesRadiographie, valuesSurgery, valuesTreatment} from "../Utils/Items";


function displayLine(values) {
    if (values !== null && values !== "NA") {
        return Object.values(values).find(elt => elt !== null && elt !== "") !== undefined
    } else return false
}

/**
 * Displays the grid for radiology/surgery categories
 *
 * @param title {string} - red title on the left column --- Previous treatment
 * @param subtitles {Array[string]} - blue subtitle on the left column, under the red title
 * @param keys_state
 * @param state {Array[Array[boolean]]} - ivd list, for each criterion (such as Spondylolisthesis) : true if the ivd has been selected, false otherwise
 *
 */
const Radiology = ({title, subtitles, keys_state, state}) => {
    return <>
        <Grid container>
            <Grid item xs={6}>{title && <p className='overview-subtitles'>{title}</p>}</Grid>
            <Grid item xs={6}/>
        </Grid>
        {state["surg_opinion"] !== undefined && <Grid container>
            <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>Surgical opinion required?</h5></Grid>
            <Grid item xs={6}><p className='details_radiosurg'>{state["surg_opinion"] !== "" ? state["surg_opinion"] : "NA"}</p></Grid>
        </Grid>}
        {state["radio_normale"] !== undefined && <Grid container>
            <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>Normal x-ray?</h5></Grid>
            <Grid item xs={6}><p className='details_radiosurg'>{state["radio_normale"] ? "Yes" : "No"}</p></Grid>
        </Grid>}
        {subtitles.map((disease, keyDisease) => {
            if (state[keys_state[keyDisease]] !== null && (Object.values(state[keys_state[keyDisease]]).includes('0') ||
                Object.values(state[keys_state[keyDisease]]).includes('1')))
            {
                let ivd_list = ''
                Object.keys(state[keys_state[keyDisease]]).forEach(ivd => {
                    if (state[keys_state[keyDisease]][ivd] === '1') ivd_list = ivd_list.concat(' ', ivd.replace('_', '-'))
                })

                return <Grid container key={keyDisease}>
                    <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>{disease}</h5></Grid>
                    <Grid item xs={6}><p className='details_radiosurg'>{ivd_list}</p></Grid>
                </Grid>
            }
            else return null
        })}
        {state["details"] !== null && state["details"] !== "" && <Grid container>
            <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>Details</h5></Grid>
            <Grid item xs={6}><p className='details_radiosurg'>{state["details"]}</p></Grid>
        </Grid>}
        <br/><br/>
    </>
}


const Treatment = ({title, keys_state, state}) => {
    return <>
        <Grid container>
            <Grid item xs={6}>{title && <p className='overview-subtitles'>{title}</p>}</Grid>
            <Grid item xs={6}/>
        </Grid>
        {keys_state.map((disease, keyDisease) => {
            if (state[disease] !== null && state[disease] !== "") return <Grid container key={keyDisease}>
                <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>{disease}</h5></Grid>
                <Grid item xs={6}><p className='details_radiosurg'>{state[disease] === "ND" ? "Not documented" : state[disease]}</p></Grid>
            </Grid>
            else return null
        })}
        <br/><br/>
    </>
}


export const DetailsPatient = ({id}) => {
    const [identity, setIdentity] = React.useState(null)
    const [med_history, setMedHistory] = React.useState(null)
    const [work, setWork] = React.useState(null)

    const [radioSigns, setRadioSigns] = React.useState(null)
    const [prevSurgery, setPrevSurgery] = React.useState(null)
    const [nextSurgery, setNextSurgery] = React.useState(null)
    const [treatment, setTreatment] = React.useState(null)
    const [radioStandard, setRadioStandard] = React.useState(null)

    React.useEffect(() => {
        getPatientDetails(id).then(response => {
            const ident = {label: response.data.label,
                sex: response.data.sex,
                age: response.data.age,
                height: response.data.height,
                weight: response.data.weight}
            setIdentity(ident)
            const medhistory = {smoke: response.data.med_history.smoke,
                diabete: response.data.med_history.diabete,
                lumbar_pain: response.data.med_history.lumbar_pain,
                rad_pain: response.data.med_history.rad_pain,
                radiculalgia: response.data.med_history.radiculalgia,
                odi: response.data.med_history.odi}
            setMedHistory(medhistory)
            const work = {work: response.data.work.work,
                employement: response.data.work.employment
            }

            setWork(work)
            setRadioSigns(response.data.radiosurgery.radiosigns)
            if (Object.values(response.data.radiosurgery.next_surg).find(elt => elt !== null) !== undefined) {
                setNextSurgery(response.data.radiosurgery.next_surg)
            }
            if (Object.values(response.data.radiosurgery.spine_op).find(elt => elt !== null) !== undefined) {
                setPrevSurgery(response.data.radiosurgery.spine_op)
            }
            if (Object.values(response.data.radiosurgery.radio_standard).find(elt => elt !== null) !== undefined) {
                setRadioStandard(response.data.radiosurgery.radio_standard)
            }
            setTreatment(response.data.radiosurgery.treatment)
        })
    }, [id])

    if (identity !== null && med_history !== null && work !== null) {
        return <Grid container id="patient-details">
            <GridSample
                titles={['Label', 'Sex', 'Age', 'Height', 'Weight']}
                names={['label', 'sex', 'age', 'height', 'weight']}
                state={identity}
                unities={[' ', ' ',' years old',' cm',' kg']}
            />
            <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
            <GridSample
                titles={['Smoke','Diabete','Lumbar pain','Radicular pain', 'Radiculalgia side', 'Oswestry Disability Index']}
                names={['smoke','diabete','lumbar_pain','rad_pain', 'radiculalgia', 'odi']}
                state={med_history}
                unities={[' ', ' ', '/10', '/10', ' ', '/100']}
            />
            <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
            <GridSample
                titles={['Work','Employement']}
                state={[work.work, work.employement]}
                unities={[' ', ' ']}
            />
            <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
            {radioSigns && <GridSample
                titles={['Main radiological signs']}
                state={[radioSigns]}
                unities={[' ']}
            />}
            <br/><br/>
            {displayLine(prevSurgery) && <Radiology
                title="Previous spine operation"
                subtitles={valuesOperation}
                keys_state={Object.keys(prevSurgery)}
                state={prevSurgery}
            />}
            {displayLine(nextSurgery) && <Radiology
                title="Surgery indication/infiltration"
                subtitles={valuesSurgery}
                keys_state={Object.keys(nextSurgery)}
                state={nextSurgery}
            />}
            {displayLine(treatment) && <Treatment
                title="Treatment"
                subtitles={valuesTreatment}
                keys_state={Object.keys(treatment)}
                state={treatment}
            />}
            {displayLine(radioStandard) && <Radiology
                title="Standard x-ray"
                subtitles={valuesRadiographie}
                keys_state={Object.keys(radioStandard)}
                state={radioStandard}
            />}
        </Grid>
    }
    else return <Grid container id="patient-details">
        <CircularProgress color="inherit" />
    </Grid>
}