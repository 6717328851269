import {getValue} from "./Overview/Overview";
import {dbRadiographie, dbSpineOperation, dbSurgery, dbVb} from "../Utils/Items";
import {LinearProgress, Stack} from "@mui/material";
import React from "react";
import {ErrorMessage, SuccessMessage} from "../Utils/ErrorManagement";

export const FinishedSymbole = ({finished, error}) => {
    if (finished && !error) return <> <i className="fas fa-check-circle" style={{color: 'var(--dark-success-color)'}}/></>
    else if (error) return <> <i className="fas fa-times-circle" style={{color: 'var(--dark-error-color)'}}/></>
    else return <>... </>
}

export const ClinicalDataUpload = ({finished, error}) => {
    return <div style={{margin: "25px auto 0"}}>
        <p>Clinical data upload{<FinishedSymbole finished={finished} error={error} />}</p>
        {finished && !error
            ? <SuccessMessage
                message="Your data has been successfully uploaded"
                strong="thank you!"
            />
            : !error
                ? <Stack sx={{ color: 'var(--dark-blue)', width: '25%', margin: 'auto' }}>
                    <LinearProgress color="inherit" />
                </Stack>
                : <ErrorMessage
                    message="An error occured while trying to upload your data"
                    strong="try again or contact the support"
                />
        }
    </div>
}

function get_val(initial_dict, line_names, values) {
    line_names.forEach((line, keyLine) => {
        const line_dict = {'t8_t9': null,'t9_t10': null,'t10_t11': null}
        values[keyLine].forEach((disc, keyDisc) => {
            if (disc !== '') {
                if (disc === true) line_dict[dbVb[keyDisc]] = 1
                else if (disc === false) line_dict[dbVb[keyDisc]] = 0
            } else line_dict[dbVb[keyDisc]] = -1
        })
        initial_dict[line] = line_dict
    })
}

export function get_surgery_values(radiology) {
    const radio_surg = {
        "radiology_signs": radiology.radiology_signs,
        "surgery": {
            "surg_opinion": radiology.surg_opinion,
            "details": radiology.surgery_details
        },
        "spine_op": {
            "details": radiology.spine_op_details
        },
        "radio": {
            "radio_normale": radiology.radio_normale,
            "details": radiology.radio_details
        },
        "treatment": radiology.treatment,
        "treatment_details": radiology.treatment_details
    }
    if (getValue(radiology.surgery)) get_val(radio_surg["surgery"], dbSurgery, radiology.surgery)
    if (getValue(radiology.spine_op)) get_val(radio_surg["spine_op"], dbSpineOperation, radiology.spine_op)
    if (getValue(radiology.radio)) get_val(radio_surg["radio"], dbRadiographie, radiology.radio)

    return radio_surg
}