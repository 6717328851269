import React from "react";
import './AddImagesPage.css'
import {ImportImages, mod_list} from "../ImportImages";
import {postFile} from "../../services/CollectorService";
import {SuiviUploadModule} from "./Modules/SuiviUploadModule";
import {Button} from "@mui/material";

const resetFilesProgress = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
}
const resetFilesFinished = { //-1 if not started yet, 0 if started, 1 if finished
    0: -1,
    1: -1,
    2: -1,
    3: -1,
    4: -1,
    5: -1,
    6: -1,
    7: -1,
    8: -1,
}

export default class AddImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            error_files: false,
            mod: '',
            error_mod: false,
            dcm_files: [],

            files_progress: resetFilesProgress,
            files_finished: resetFilesFinished,

            error_upload: [],
            finished: true,
        }

    }

    handleFilesStarted = mod => {
        const previous = this.state.files_finished
        previous[mod] = 0
        this.setState({files_finished: previous})
    }
    handleFilesProgress = (mod, progress) => {
        const previous = this.state.files_progress
        previous[mod] = progress
        if (progress === 100) this.handleFilesStarted(mod)
        this.setState({files_progress: previous})
    }
    handleFilesFinished = mod => {
        const previous = this.state.files_finished
        previous[mod] = 1
        this.setState({files_finished: previous})
    }

    handleFile = e => {
        const fd = this.state.files.slice()
        Array.from(e.target.files).forEach(file => {
            fd.push(file)
        })
        this.setState({files: fd})
        e.target.value = ''
    }
    handleModality = e => {
        this.setState({mod: e.target.value})
        if (this.state.mod !== "") this.setState({error_mod: false})
    }
    handleSubmit = () => {
        if (this.state.files.length !== 0 && this.state.mod !== '') {
            const liste = this.state.dcm_files.slice()
            liste.push([this.state.files, this.state.mod])
            this.setState({dcm_files: liste, files: [], error_mod: false, error_files: false})
        } else {
            if (this.state.mod === "") this.setState({error_mod: true})
            if (this.state.files.length === 0) this.setState({error_files: true})
        }
    }
    handleDeleteMod = (modality) => {
        const liste = this.state.dcm_files.slice()
        this.setState({dcm_files: liste.filter((mod, keyMod) => keyMod !== modality)})
    }
    handleDeleteLine = (modality, line) => {
        if (this.state.dcm_files[modality][0].length === 1) this.handleDeleteMod(modality)
        else {
            const liste = this.state.dcm_files.slice()
            liste[modality][0] = liste[modality][0].filter((image, keyImage) => keyImage !== line)
            this.setState({dcm_files: liste})
        }
    }

    postNewFiles = () => {
        this.setState({finished: false})
        this.state.dcm_files.forEach(folder => {
            const id = mod_list.findIndex(elt => elt === folder[1]) + 4
            postFile(this.props.patient_id, id, folder[0], progress => this.handleFilesProgress(id, progress))
                .then(response => {
                    if (response.status === 200) this.handleFilesFinished(id)
                })
                .then(() => {
                    if (this.state.files_finished === this.state.dcm_files.length) {
                        this.setState({
                            finished: true,
                            add_images: false,
                            selected_patient: -1,
                            files: [],
                            error_files: false,
                            mod: '',
                            error_mod: false,
                            dcm_files: []
                        })
                    }
                })
                .catch(error => {
                    const errors = this.state.error_upload.slice()
                    errors.push(id)
                    this.handleFilesFinished(id)
                    this.setState({error_upload: errors})
                    if (this.state.files_finished === this.state.dcm_files.length) {
                        this.setState({finished: true})
                    }
                })
        })
    }

    render() {
        if (!this.state.finished) return <SuiviUploadModule
            series={this.state.dcm_files}
            files_progress={this.state.files_progress}
            files_finished={this.state.files_finished}
            error={this.state.error_upload}
        />
        else return <>
            <ImportImages
                className='upload-image-home'
                handleFile={e => this.handleFile(e)}
                files={this.state.files}
                dcm={this.state.dcm_files}
                errorFile={this.state.error_files}
                handleModality={e => this.handleModality(e)}
                modality={this.state.mod}
                errorMod={this.state.error_mod}
                handleSubmit={() => this.handleSubmit()}
                handleDeleteMod={modality => this.handleDeleteMod(modality)}
                handleDeleteLine={(modality, line) => this.handleDeleteLine(modality, line)}
            />
            {this.state.dcm_files.length > 0 && <Button
                style={{
                    backgroundColor: 'rgba(19, 55, 90, 0.75)',
                    color: 'white',
                    margin: '5px auto',
                    width: '75vw'
                }}
                onClick={this.postNewFiles}
            >
                Add images to patient
            </Button>}
        </>
    }
}