import React from "react";
import {styled} from "@mui/material/styles";
import {FormControl, FormControlLabel, Radio, RadioGroup, Slider} from "@mui/material";
import {MenuItem, Select} from "@material-ui/core";
import {MenuProps} from "../NewPatient/Steps/Steps";

const NASlider = styled(Slider)({
    '& .MuiSlider-thumb': {
        display: 'none'
    }
})

const smoke_choices = ["NA", "No", "Previous", "Yes"]
const diabete_choices = ["NA", "No", "Type 1", "Type 2"]
const rad_side_choices = ["NA", "Left", "Right", "Bilateral"]
const pain_marks = [
    {label: '0', value: 0},
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10}
]
export const Smoke = ({color, value, handleChange}) => {
    const col = color !== null && color !== undefined ? color : 'var(--dark-blue)'
    return <div className='identity-element-medhistory'>
        <p>Smoke</p>
        <div className='sex'>
            <RadioGroup
                name="smoke"
                style={{color: col}}
                value={value}
                onChange={handleChange}
            >
                <div className='sex'>
                    {smoke_choices.map((option, keyOption) => {
                        return <FormControlLabel
                            key={keyOption}
                            style={{margin: 0, padding: "2px"}}
                            value={option}
                            control={<Radio size="small" style={{color: col}} />}
                            label={option}
                        />
                    })}
                </div>
            </RadioGroup>
        </div>
    </div>
}

export const Diabete = ({color, value, handleChange}) => {
    const col = color !== null && color !== undefined ? color : 'var(--dark-blue)'
    return <div className='identity-element-medhistory'>
        <p>Diabete</p>
        <div className='sex'>
            <RadioGroup
                name="diabete"
                style={{color: col}}
                value={value}
                onChange={handleChange}
            >
                <div className='sex'>
                    {diabete_choices.map((option, keyOption) => {
                        return <FormControlLabel
                            key={keyOption}
                            style={{margin: 0, padding: "2px"}}
                            value={keyOption-1}
                            control={<Radio size="small" style={{color: col}} />}
                            label={option}
                        />
                    })}
                </div>
            </RadioGroup>
        </div>
    </div>
}

export const LumbRadPain = ({value, handleChange}) => {
    if (value >= 0 && value <= 10) return <Slider
        style={{color: 'var(--dark-blue)', minWidth: '30vw'}}
        step={1}
        marks={pain_marks}
        min={0}
        max={10}
        defaultValue={0}
        value={value}
        name="lumbar_pain"
        onChange={handleChange}
    />
    else return <NASlider
        style={{color: 'var(--dark-blue)', minWidth: '30vw'}}
        step={1}
        marks={pain_marks}
        min={0}
        max={10}
        defaultValue={0}
        value={-1}
        name="lumbar_pain"
        onChange={handleChange}
    />
}


export const LumbRadPainBis = ({name, value, handleChange, handleReset}) => {
    return <div className='identity-element-medhistory'>
        <p>{name} pain {value >= 0 && <button className='reset-pain-btn' onClick={handleReset}>reset</button>}</p>
        <FormControl>
            <LumbRadPain
                value={value}
                handleChange={handleChange}
            />
        </FormControl>
    </div>
}

export const Radiculalgia = ({color, value, handleChange}) => {
    const col = color !== null && color !== undefined ? color : 'var(--dark-blue)'
    return <div className='identity-element-medhistory'>
        <p>Radiculalgia side</p>
        <div className='sex'>
            <RadioGroup
                name="radiculalgia"
                style={{color: col}}
                value={value}
                onChange={handleChange}
            >
                <div className='sex'>
                    {rad_side_choices.map((option, keyOption) => {
                        return <FormControlLabel
                            key={keyOption}
                            style={{margin: 0, padding: "2px"}}
                            value={option}
                            control={<Radio size="small" style={{color: col}} />}
                            label={option}
                        />
                    })}
                </div>
            </RadioGroup>
        </div>
    </div>
}

export const ODI = ({value, handleChange}) => {
    const [open, setOpen] = React.useState(false)
    return <div className='identity-element-medhistory'>
        <p>Oswestry Disability Index</p>
        <FormControl style={{width: '100%'}}>
            <Select
                open={open}
                name='odi'
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={(value < 0 || value > 100) ? '' : value}
                onChange={handleChange}
                MenuProps={MenuProps}
            >
                <MenuItem value="">
                    <em>NA</em>
                </MenuItem>
                {Array.from({length:101}, (_,i) => i).map(odi => {
                    return <MenuItem key={odi} value={odi}>{odi}</MenuItem>
                })}
            </Select>
        </FormControl>
    </div>
}