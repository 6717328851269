import {Button, FormControl} from "@mui/material";
import {MenuItem, Select} from "@material-ui/core";
import {MenuProps} from "../../NewPatient/Steps/Steps";
import {mod_list} from "../../ImportImages";

export const ImportModule = ({dcm, handleFile, files, errorFile, modality, handleModality, errorMod, handleSubmit}) => <div className='choose-folder'>
    <p style={{margin: '10px auto'}}>Select images</p>
    <div className='add-images-form'>
        { files.length === 0
            ? <>
                <label htmlFor="folder_upload">
                    <p className="folder_upload">
                        CHOOSE A{dcm.length > 0 && 'NOTHER'} FOLDER
                    </p>
                </label>
                <input id="folder_upload"
                       directory=""
                       webkitdirectory=""
                       type="file"
                       accept="image/*, /dicom, .dcm, image/dcm, /dcm, .dicom .ima"
                       multiple
                       style={{display: 'none'}}
                       onChange={event => handleFile(event)}
                />
                <p style={{color: 'var(--dark-blue)', margin: '3px 0', fontSize: '14px'}}>or</p>
                <label htmlFor="files_upload">
                    <p className="files_upload">
                        CHOOSE {dcm.length > 0 && 'OTHER '}FILES
                    </p>
                </label>
                <input id="files_upload"
                       directory=""
                       type="file"
                       accept="image/*, /dicom, .dcm, image/dcm, /dcm, .dicom .ima"
                       multiple
                       style={{display: 'none'}}
                       onChange={event => handleFile(event)}
                />
            </>
            : <p style={{color: "rgb(19, 55, 90)"}}>{files.length} files selected</p>
        }
        {errorFile ? <p style={{color: "red", fontSize: '10px'}}>Select a folder first</p> : <></>}
        <p  style={{margin: '10px auto'}}>Select modality</p>
        <FormControl>
            <Select
                value={modality}
                onChange={handleModality}
                MenuProps={MenuProps}
            >
                {mod_list.map((mod, keyMod) => <MenuItem value={mod} key={keyMod}>{mod}</MenuItem>)}
            </Select>
        </FormControl>
        {errorMod ? <p style={{color: "red", fontSize: '10px'}}>Select a modality first</p> : <></>}
        <Button
            id="upload-image-submit-btn"
            onClick={handleSubmit}
        >
            Submit
        </Button>
    </div>
</div>
