import React from "react";
import {Diabete, LumbRadPainBis, ODI, Radiculalgia, Smoke} from "../../RadioComponents/MedHistory";

const SmokeDiabete = ({smoke, diabete, handleChange}) => {
    return <div>
        <Smoke value={smoke} handleChange={handleChange}/>
        <Diabete value={diabete} handleChange={handleChange}/>
    </div>
}

/**
 * Displays the fields related to patient medical history (step 1).
 *
 * @param {boolean} error_message
 * @param {String} smoke ∈ {'No', 'Previous', 'Yes'}
 * @param {int} diabete ∈ {0, 1, 2}
 * @param {int} lumbar_pain ∈ {-1, 0, 1, ... 9, 10}
 * @param {int} rad_pain ∈ {-1, 0, 1, ... 9, 10}
 * @param {String} radiculalgia ∈ {'NA', 'Left', 'Right', 'Bilateral}
 * @param {int} odi ∈ [-1, 100]
 * @param {function} handleChange
 * @param {function} handleBlur - for input element odi
 * @param {function} handleChangeCommitted - for sliders lumbar_pain and rad_pain
 *
 * @returns {JSX.Element}
 */
export const MedicalHistory = ({smoke, diabete, lumbar_pain, rad_pain, radiculalgia, odi, handleChange, handleChangeCommited}) => {
    const handleResetPain = (label) => handleChangeCommited(label, -1)

    return <div className='identity-medhistory'>
        <div className='medhistory-box'>
            <SmokeDiabete smoke={smoke} diabete={diabete} handleChange={handleChange} />
            <div className='identity-element-medhistory'>
                <LumbRadPainBis
                    name="Lumbar"
                    value={lumbar_pain}
                    handleReset={() => handleResetPain('lumbar_pain')}
                    handleChange={(e, value) => handleChangeCommited('lumbar_pain', value)}
                />
            </div>
        </div>
        <div className='medhistory-box'>
            <LumbRadPainBis
                name="Radicular"
                value={rad_pain}
                handleReset={() => handleResetPain('rad_pain')}
                handleChange={(e, value) => handleChangeCommited('rad_pain', value)}
            />
            <Radiculalgia value={radiculalgia} handleChange={handleChange}/>
            <ODI
                value={odi}
                handleChange={handleChange}
            />
        </div>
    </div>
}