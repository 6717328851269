import axios from "axios";

const port = `${process.env.REACT_APP_IP}/` //TODO: env variable pour l'adresse ip

export default axios.create({
    baseURL: port,
    headers: {
        Authorization: `JWT ${sessionStorage.getItem('token')}`,
        "Content-type": "application/json"
    }
});