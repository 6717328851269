import {Grid} from "@material-ui/core";
import {modalities} from "../Utils/Items";
import React from "react";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';


export const GridPatient = ({small_win, win_size, widths, color, name, identity, med_history, images, handleAddImages, handleUpdatePatient, handleClickPatient}) => {
    return <Grid container style={{backgroundColor: color, cursor: 'default'}}>
        <Grid container>
            <Grid item xs={widths[0]}>
                <Grid container>
                    <Grid item xs={6}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>{name}</p></Grid>
                    <Grid item xs={2}><SearchIcon id="patient-details-icon" onClick={handleClickPatient}/></Grid>
                    <Grid item xs={2}><EditIcon id="patient-details-icon" onClick={handleUpdatePatient}/></Grid>
                    <Grid item xs={2}><AddAPhotoIcon id="add-images-icon" onClick={handleAddImages}/></Grid>
                </Grid>
            </Grid>
            {identity && <Grid item xs={widths[1]}>
                <Grid container>
                    {['sex', 'age', 'height', 'weight'].map((label, keyLabel) => {
                        return <Grid key={keyLabel} item xs={3}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>
                            {identity[label] !== -1 ? identity[label] : 'NA'}
                        </p></Grid>
                    })}
                </Grid>
            </Grid>}
            {med_history && win_size !== 'xsmall' && win_size !== 'small' && <Grid item xs={widths[2]} style={{display: 'flex'}}>
                <Grid container>
                    <Grid item xs={small_win ? 6 : 2}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>
                        {med_history['smoke'] ? med_history['smoke'] : 'Unknown'}
                    </p></Grid>
                    <Grid item xs={small_win ? 6 : 2}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>
                        {med_history['diabete'] === -1 ? 'NA' : med_history['diabete'] === 0 ? 'No' : 'Type ' + med_history['diabete']}
                    </p></Grid>
                    {win_size === 'large' && <>
                        <Grid item xs={2}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>
                            {med_history['lumbar_pain'] !== -1 ? med_history['lumbar_pain'] + '/10' : 'NA'}
                        </p></Grid>
                        <Grid item xs={2}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>
                            {med_history['rad_pain'] !== -1 ? med_history['rad_pain'] + '/10' : 'NA'}
                        </p></Grid>
                        <Grid item xs={2}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>
                            {med_history['radiculalgia']}
                        </p></Grid>
                        <Grid item xs={2}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>
                            {med_history['odi'] !== -1 ? med_history['odi'] + '/100' : 'NA'}
                        </p></Grid>
                    </>}
                </Grid>
            </Grid>}
            {win_size !== 'xsmall' && <Grid item xs={widths[3]}>
                {images && images.map((mod, keyMod) => {
                    return <p style={{fontSize: '16px', color: 'var(--dark-blue)'}} key={keyMod}>
                        {modalities[mod]}
                    </p>
                })}
            </Grid>}
        </Grid>
    </Grid>
}