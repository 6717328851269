import React from 'react'
import {NavBar} from "../NavBar/NavBar";
import AddImages from "./AddImages";
import {Redirect, useLocation} from "react-router-dom";
import {CancelRedBtn} from "../Utils/CustomButtons";

export const AddImagesPageHistory = props => {
    const state = useLocation().state
    const patient_id = state !== undefined ? state.id : null
    return <AddImagesPage {...props} patient_id={patient_id}/>
}

class AddImagesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cancel: false
        }
    }

    render() {
        const patient_id = this.props.patient_id
        if (patient_id === null || this.state.cancel) return <Redirect to={{pathname: '/Home'}}/>
        else return <div className='add-images-page'>
            <NavBar
                username={this.props.name}
                handleLogout={this.props.logout}
            />
            <div style={{display: 'flex', flexDirection: 'column', width: '100vw'}}>
                <h1 style={{textAlign: 'center', margin: '25px auto'}}>Add images to patient {patient_id}</h1>
                <AddImages patient_id={patient_id}/>
                <CancelRedBtn onClick={() => this.setState({cancel: true})}>
                    CANCEL
                </CancelRedBtn>
            </div>
        </div>
    }
}