import React from "react"
import {ImportImages} from "../ImportImages";


export const UploadImageStep = ({images, dcm, uploadImages, uploadDicoms}) => {
    const [errorFile, setErrorFile] = React.useState(false)
    const [errorMod, setErrorMod] = React.useState(false)
    const [modality, setModality] = React.useState("")

    const [files, setFiles] = React.useState([])

    const handleModality = e => {
        setModality(e.target.value)
        if (modality !== "") setErrorMod(false)
    }

    const handleFile = e => {
        const fd = files.slice()
        Array.from(e.target.files).forEach(file => {
            fd.push(file)
        })
        setFiles(fd)
        e.target.value = ''
    }

    const handleSubmit = () => {
        if (files.length !== 0 && modality !== "") {
            const liste = dcm.slice()
            liste.push([files, modality])
            uploadDicoms(liste)
            setModality("")
            setFiles([])
            setErrorFile(false)
            setErrorMod(false)
        } else {
            if (modality === "") setErrorMod(true)
            if (files.length === 0) setErrorFile(true)
        }
    }

    const handleDeleteMod = (modality) => {
        const liste = dcm.slice()
        uploadDicoms(liste.filter((mod, keyMod) => keyMod !== modality))
    }

    const handleDeleteLine = (modality, line) => {
        if (dcm[modality][0].length === 1) handleDeleteMod(modality)
        else {
            const liste = dcm.slice()
            liste[modality][0] = liste[modality][0].filter((image, keyImage) => keyImage !== line)
            uploadDicoms(liste)
        }
    }

    return <ImportImages
        className='identity'
        handleFile={e => handleFile(e)}
        errorFile={errorFile}
        errorMod={errorMod}
        modality={modality}
        handleModality={e => handleModality(e)}
        handleSubmit={() => handleSubmit()}
        files={files}
        dcm={dcm}
        handleDeleteLine={(modality, line) => handleDeleteLine(modality, line)}
        handleDeleteMod={(modality) => handleDeleteMod(modality)}
    />
}