import {mod_list} from "../../ImportImages";
import {LinearProgress, Stack} from "@mui/material";
import React from "react";
import {ErrorMessage, SuccessMessage} from "../../Utils/ErrorManagement";
import {FinishedSymbole} from "../../NewPatient/utils";
import {ProgressBarModule} from "./ProgressBarModule";

export const SuiviUploadModule = ({series, files_progress, files_finished, error}) => <div>
    {series.map((folder, keyFolder) => {
        const id = mod_list.findIndex(elt => elt === folder[1])+4
        const mod_error = error.includes(id)
        if (!mod_error && files_finished[id] !== 1) return <div className="suivi-upload-folder" key={keyFolder}>
            <p>{folder[1]}</p>
            <p style={{color: 'var(--dark-blue)', marginBottom: "5px"}}>Uploading files<FinishedSymbole finished={files_progress[id] === 100} error={false}/></p>
            <Stack sx={{color: 'var(--dark-blue)'}}>
                <LinearProgress color="inherit" value={files_progress[id] ? files_progress[id] : 0} variant="determinate"/>
            </Stack>
            {files_finished[id] >= 0 && <ProgressBarModule
                nbr_images={folder[0].length}
                finished={files_finished[id] === 1}
            />}
        </div>
        else if (mod_error) return <ErrorMessage
            key={keyFolder}
            title={` - modality ${folder[1]}`}
            message="An error occurred while uploading the files"
            strong="try again or contact the support"
        />
        else if (files_finished[id] === 1) return <SuccessMessage
            key={keyFolder}
            title={` - modality ${folder[1]}`}
            message="Your images has been successfully uploaded!"
            strong="thank you!"
        />
        else return null
    })}
</div>
