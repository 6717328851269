import http from '../http-common'

export const putCollectorData = (disc_data, setProgress) => {
    return http.put(`/core/collector/`, disc_data, {
        onUploadProgress: function( progressEvent ) {
            setProgress(( progressEvent.loaded / progressEvent.total ) * 100 )
        }})
}

export const getPatientDetails = (id) => {
    return http.get(`/core/patient/${id}/`)
}

export const getListofPatients = () => {
    return http.get('/core/collector/user/')
}

export const getModalities = (patient_id) => {
    return http.get(`/core/collector/${patient_id}/images/`)
}

export const postFile = async (patient_id, modality, files, setProgress) => {
    const fd = new FormData()
    files.forEach((file, keyFile) => {
        fd.append('file_'+keyFile, file)
    })
    return await http.post(`/core/collector/upload/${patient_id}/${modality}/`,  fd, {
        onUploadProgress: function( progressEvent ) {
            setProgress(( progressEvent.loaded / progressEvent.total ) * 100 )
        }})
}