import {surgeryTabs, valuesAll, valuesRadiographie, vb} from "../../Utils/Items";
import React from "react";
import {Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField} from "@mui/material";

/**
 * Displays the surgery checkboxes for each tab.
 *
 * @param {Boolean} surgery - if true, surgery opinion checkboxes must be displayed
 * @param {Boolean} surg_opinion - if surgery is true, displayed the data from database
 * @param {String} name - name to be displayed in the placeholder of the text area
 * @param {Array} data - data stored in state
 * @param {String} details - text area value stored in state
 * @param {String} width - width of the grid component
 * @param {String[]} values - name of the lines
 * @param {Boolean[]} disabled - boolean values of each line expressing if the checkboxes of the line are disabled or not
 * @param {function} handleDisabled
 * @param {function} handleChange
 * @param {function} handleDetails
 *
 * @returns {JSX.Element}
 */
const SurgeryTab = ({surgery, surg_opinion, name, data, details, width, values, disabled, handleDisabled, handleChange, handleDetails, handleSurgOpinion}) => {
    return <div className='tab'>
        <Grid container>
            <br/>
            {surgery && <Grid container>
                <br/>
                <div className='sex'>
                    <p style={{margin: 'auto', padding: '0 10px'}}>Surgical opinion required?&nbsp;</p>
                    <RadioGroup
                        value={surg_opinion}
                        onChange={event => handleSurgOpinion(event.target.value)}
                    >
                        <div className='sex'>
                            <FormControlLabel value={""} control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />} label="NA" />
                            <FormControlLabel value={"Yes"} control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />} label="No" />
                        </div>
                    </RadioGroup>
                </div>
            </Grid>}
            <Grid container style={{width: width, marginLeft: '4vw'}}>
                <Grid item xs={3}/>
                <Grid item xs={9}>
                    <Grid container>
                        <Grid item xs={1}/>
                        {vb.map(disc => {
                            return <Grid item xs={1} key={disc}><p style={{color: 'var(--dark-blue)'}}>{disc}</p></Grid>
                        } )}
                    </Grid>
                </Grid>
                {values.map((valueName, keyName) => {
                    return (
                        <Grid container key={keyName}>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={<Checkbox
                                        style={{color: 'var(--dark-blue)'}}
                                        size="small"
                                        checked={!disabled[keyName]}
                                    />}
                                    onChange={() => handleDisabled(keyName)}
                                    label={valueName}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container>
                                    <Grid item xs={1}/>
                                    {vb.map((value, key) => {
                                        return (
                                            <Grid item xs={1} key={key}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        style={{color: disabled[keyName] ? "lightgrey" : 'var(--dark-blue)'}}
                                                        size="small"
                                                        checked={data[keyName][key] === true || data[keyName][key] === false ? data[keyName][key] : false}
                                                        disabled={disabled[keyName]}
                                                        indeterminate={!!disabled[keyName]}
                                                    />}
                                                    onChange={() => handleChange(keyName, key)}
                                                    labelPlacement="top"
                                                    label=''
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
        <div>
            <TextField
                multiline
                maxRows={3}
                value={details}
                placeholder={"Additional important information about " +name+"..."}
                variant="outlined"
                style={{width: '100%', color: 'var(--dark-blue)'}}
                onChange={event => handleDetails(event.target.value)}
            />
        </div>
    </div>
}

/**
 * Displays the fields related to patient history of surgeries and radiologies (step 3).
 * @param {Integer} step : in {0, 1, 2, 3}
 *
 * @param {String} radiology_signs
 *
 * @param surg_opinion
 * @param {Array} surgery : surgery data sent from ClinicalData component
 * @param {Array} spine_op
 * @param {Array} treatment
 * @param {Array} radio
 *
 * @param {String} surgery_details
 * @param {String} spine_op_details
 * @param {String} treatment_details
 * @param {String} radio_details
 *
 * @param initRadiology
 * @param {function} resetRadiology : (tab, patho) -> resetRadiology(tab, patho)
 * @param {function} handleDetails : (tab, value) -> handleDetails(tab, value)
 * @param {function} handleRadiology : (tab, patho, disc) -> handleRadiology(tab, patho, disc)
 * @param {function} handleTreatment : (treatment, value) -> handleTreatment(treatment, value)
 *
 * @param radio_normale
 *
 * @returns {JSX.Element}
 */
export const Surgery = ({step, radiology_signs, surg_opinion, surgery, spine_op, treatment, radio, surgery_details, spine_op_details, treatment_details, radio_details, initRadiology, resetRadiology, handleDetails, handleRadiology, handleTreatment, radio_normale}) => {
    const [disabledSurgery, setDisabledSurgery] = React.useState(surgery.map(x => x.includes('')))
    const [disabledOperation, setDisabledOperation] = React.useState(spine_op.map(x => x.includes('')))
    const [disabledRadiographie, setDisabledRadiographie] = React.useState(radio.map(x => x.includes('')))

    const otherVb = [false, false, false, false, false]

    const width = '98vw'

    const data = [surgery, spine_op, treatment, radio]
    const names = ["surgery indication/infiltration", "previous spine operation", "previous treatment", "standard x-ray"]
    const disables = [disabledSurgery, disabledOperation, null, disabledRadiographie]

    /**
     * Disables a pathology in a specified tab and reset disc values (false) for that tab.
     * @param {int} tab
     * @param {int} patho - line to be disabled
     */
    const handleDisabled = (tab, patho) => {
        if (tab === 0) {
            const dis = disabledSurgery.slice()
            dis[patho] = !dis[patho]
            setDisabledSurgery(dis)
            if (!disabledSurgery[patho]) resetRadiology(tab, patho)
            else initRadiology(tab, patho)
        } else if (tab === 1) {
            const dis = disabledOperation.slice()
            dis[patho] = !dis[patho]
            setDisabledOperation(dis)
            if (!disabledOperation[patho]) resetRadiology(tab, patho)
            else initRadiology(tab, patho)
        } else if (tab === 3) {
            const dis = disabledRadiographie.slice()
            dis[patho] = !dis[patho]
            setDisabledRadiographie(dis)
            if (!disabledRadiographie[patho]) resetRadiology(tab, patho)
            else initRadiology(tab, patho)
        }
    }

    return <div className='step-surgery'>
        <div style={{margin: 'auto', width: '98vw'}}>
            <p>Main radiological sign</p>
            <TextField
                multiline
                maxRows={3}
                value={radiology_signs}
                placeholder="Main radiological signs that you want to operate..."
                variant="outlined"
                style={{width: width, color: 'var(--dark-blue)', backgroundColor: 'rgba(215, 174, 103, 0.1)'}}
                onChange={event => handleDetails(0, event.target.value)}
            />
        </div>
        <div className='step-surgery-tabs'>
            <p style={{margin: "15px auto"}}>{surgeryTabs[step]}</p>
            {step === 2 // treatment ?
                ? <div className='tab'>
                    <Grid container>
                        <br/>
                        <Grid container style={{width: width, marginLeft: '4vw'}}>
                            <Grid item xs={4}/>
                            <Grid item xs={8}>
                                <Grid container spacing={1}>
                                    {["No", "Previous", "Yes", "Not documented"].map(disc => {
                                        return(
                                            <Grid item xs={3} key={disc}>
                                                <p style={{color: 'var(--dark-blue)', textAlign: 'start', paddingLeft: '5px'}}>{disc}</p>
                                            </Grid>
                                        )
                                    } )}
                                </Grid>
                            </Grid>
                            {valuesAll[2].map((name, keyName) => {
                                return (
                                    <Grid container key={keyName}>
                                        <Grid item xs={4}><p style={{color: 'var(--dark-blue)', fontFamily: 'Helvetica', textAlign: 'start', fontSize: '16px', margin: 'auto'}}>{name}</p></Grid>
                                        <Grid item xs={8}>
                                            <RadioGroup value={treatment[keyName]} onChange={e => handleTreatment(keyName, e.target.value)}>
                                                <Grid container spacing={1}>
                                                    {["No", "Previous", "Yes", "ND"].map((value, key) => {
                                                        return (
                                                            <Grid key={key} item xs={3}>
                                                                <FormControlLabel
                                                                    value={value}
                                                                    control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />}
                                                                    style={{margin: 'auto'}}
                                                                    label=''
                                                                />
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <div>
                        <TextField
                            multiline
                            maxRows={3}
                            value={treatment_details}
                            placeholder="Additional important information about treatment..."
                            variant="outlined"
                            style={{width: '100%', color: 'var(--dark-blue)'}}
                            onChange={event => handleDetails(3, event.target.value)}
                        />
                    </div>
                </div>
                : step === 3 //standard x-ray ?
                    ? <div className='tab'>
                        <Grid container>
                            <br/>
                            <div className='sex'>
                                <p style={{margin: 'auto', padding: '0 10px'}}>Normal x-ray?&nbsp;</p>
                                <RadioGroup
                                    value={radio_normale}
                                    onChange={event => handleDetails(6, JSON.parse(event.target.value))}
                                >
                                    <div className='sex'>
                                        <FormControlLabel value={true} control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio size="small" style={{color: 'var(--dark-blue)'}} />} label="No" />
                                    </div>
                                </RadioGroup>
                            </div>
                            {!radio_normale && <Grid container style={{width: width, marginLeft: '4vw'}}>
                                <Grid item xs={3}/>
                                <Grid item xs={9}>
                                    <Grid container>
                                        {otherVb[0] && <Grid item xs={1}><p style={{color: 'var(--dark-blue)'}}>T6/T7</p></Grid>}
                                        {otherVb[1] && <Grid item xs={1}><p style={{color: 'var(--dark-blue)'}}>T7/T8</p></Grid>}
                                        {otherVb[2] && <Grid item xs={1}><p style={{color: 'var(--dark-blue)'}}>T8/T9</p></Grid>}
                                        {otherVb[3] && <Grid item xs={1}><p style={{color: 'var(--dark-blue)'}}>T9/T10</p></Grid>}
                                        {otherVb[4] && <Grid item xs={1}><p style={{color: 'var(--dark-blue)'}}>T10/T11</p></Grid>}
                                        {vb.map(disc => {
                                            return <Grid item xs={1} key={disc}><p style={{color: 'var(--dark-blue)'}}>{disc}</p></Grid>
                                        } )}
                                    </Grid>
                                </Grid>
                                {valuesRadiographie.map((valueName, keyName) => {
                                    //if (keyName === 0) return <></>
                                    return <Grid container key={keyName}>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    style={{color: 'var(--dark-blue)'}}
                                                    size="small"
                                                    checked={!disabledRadiographie[keyName]}
                                                />}
                                                onChange={() => handleDisabled(3, keyName)}
                                                label={valueName}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Grid container>
                                                {vb.map((value, key) => {
                                                    return <Grid item xs={1} key={key}>
                                                        {keyName !== 0 && <FormControlLabel
                                                            control={<Checkbox
                                                                style={{color: disabledRadiographie[keyName] ? "lightgrey" : 'var(--dark-blue)'}}
                                                                size="small"
                                                                checked={data[3][keyName][key] === true || data[3][keyName][key] === false ? data[3][keyName][key] : false}
                                                                disabled={disabledRadiographie[keyName]}
                                                                indeterminate={!!disabledRadiographie[keyName]}
                                                            />}
                                                            onChange={() => handleRadiology(3, keyName, key)}
                                                            labelPlacement="top"
                                                            label=''
                                                        />}
                                                    </Grid>
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                })}
                            </Grid>}
                        </Grid>
                        <div>
                            <TextField
                                multiline
                                maxRows={3}
                                value={radio_details}
                                placeholder={"Additional important information about " +names[3]+"..."}
                                variant="outlined"
                                style={{width: '100%', color: 'var(--dark-blue)'}}
                                onChange={event => handleDetails(4, event.target.value)}
                            />
                        </div>
                    </div>
                    : <SurgeryTab //step = 0 or step = 1
                        surgery={step === 0}
                        surg_opinion={surg_opinion}
                        name={names[step]}
                        data={data[step]}
                        details={step === 0 ? surgery_details : spine_op_details}
                        width={width}
                        values={valuesAll[step]}
                        disabled={disables[step]}
                        handleDisabled={patho => handleDisabled(step, patho)}
                        handleChange={(patho, disc) => handleRadiology(step, patho, disc)}
                        handleDetails={value => handleDetails(step+1, value)}
                        handleSurgOpinion={value => handleDetails(5, value)}
                    />
            }
        </div>
    </div>
}